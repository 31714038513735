//import React, { useState, useEffect } from 'react'
//import { UserContext } from '../reusable/UserContext';
import Cookies from 'universal-cookie';
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index';
import React/* , { useContext } */ from 'react';
// import { UserContext } from "../reusable/UserContext";

const cookies = new Cookies();

export const TheLayout = () => {


  // const { userData } = useContext(UserContext);

  return (
      <div className="c-app c-default-layout">
        <TheSidebar 
        /* idRol = {userData.idRol}
        idUser= {userData.idUser} */
        idRol = {cookies.get('850b78c59cc14a624092beb627a749e7')}
        idUser= {cookies.get('21db768ea9cf0983953638fc381728bd')}
        />
        <div className="c-wrapper">
          <TheHeader/>
          <div className="c-body">
            <TheContent/>
          </div>
          <TheFooter/>
        </div>
      </div>     
  )
}

export default TheLayout
