//import React from 'react'
import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserContext } from "../reusable/UserContext";
import {
  CCreateElement,
  CSidebar,
  CImg,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';

// import CIcon from '@coreui/icons-react';
import taller  from '../assets/icons/logo.jpg';
// import Cookies from 'universal-cookie';

// sidebar nav config
//import navigation from './_nav'


const TheSidebar = (props) => {

  const {idRol, idUser }= props;
  
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow);
  const [data, setData] = useState([]);

  const baseURL = process.env.REACT_APP_API_URL;

  // const { userData } = useContext(UserContext);

  function postData(){    
    fetch(baseURL+'admin/menu/lista', {           
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({
        idRol: idRol,
        idUsuario: idUser
      })
      }).then((response) => response.json())
      .then((responseJson) => {
        setData(responseJson.menu);
        //console.log('CAsi...'+ JSON.stringify(responseJson.menu));
      })
      .catch((error) => {
        //Error
        alert(JSON.stringify(error));
        console.error(error);
      });    
  }

  useEffect(()=>{
    if (idRol > 0){
      postData();
    }
    
  },[]);

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
      <div >
          <CImg
            src={taller}
            className="menu_c-sidebar"
            alt="admin@bootstrapmaster.com"
          />
        </div>
        {/* <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={5}
        /> */}
      </CSidebarBrand>

      <CSidebarNav>
        <CCreateElement
          //items={navigation}
          items={data}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
//export default TheSidebar;