import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies  from 'universal-cookie';
import {
/*   CBadge, */
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { UserContext } from '../reusable/UserContext';
import { types } from '../reusable/types';
import taller  from '../assets/icons/user.jpg';

const cookies = new Cookies();

const TheHeaderDropdown = () => {
  
  const { dispatch } = useContext(UserContext);
  const history = useHistory();

  const handleLogout = () => {    
    // console.log('Sali...');
    /* cookies.remove('850b78c59cc14a624092beb627a749e7', {path: "/login"});  //id_usuario
    cookies.remove('21db768ea9cf0983953638fc381728bd', {path: "/login"}); //id_taller
    cookies.remove('taller', {path: "/login"}); 
    cookies.remove('nombrecompleto', {path: "/login"});   
    
    document.cookie = "850b78c59cc14a624092beb627a749e7=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "21db768ea9cf0983953638fc381728bd=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "taller=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "nombrecompleto=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; */
  
    history.replace('/login');
  
    dispatch({
        type: types.logout
    });
  }
    
  return (
    
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={taller}
            className="c-avatar-img"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Cuenta</strong>
        </CDropdownItem>        
        
        <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />Perfil
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Ajustes
        </CDropdownItem>        
        <CDropdownItem divider />
        <CDropdownItem>
            <CButton size="sm" color="danger" className="mr-2" onClick={ handleLogout }>
              <CIcon name="cil-lock-locked" /> Cerrar sesión
            </CButton>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
