import React from 'react';

//-----------Administración-------------
const Usuario = React.lazy(() => import('./views/admin/users/Usuario'));
const FormUser = React.lazy(() => import('./views/admin/users/FormUser'));
const Rol = React.lazy(() => import('./views/admin/roles/Rol'));
const FormRol = React.lazy(() => import('./views/admin/roles/FormRol'));
const SunCalc = React.lazy(() => import('./views/admin/sol/FormSunCalc'));

//------Fin de Administración-----------

//--------Catálogos-------------
const Cliente = React.lazy(() => import('./views/catalogos/clientes/Cliente'));
const FormCliente = React.lazy(() => import('./views/catalogos/clientes/FormCliente'));
const FormAuto = React.lazy(() => import('./views/catalogos/clientes/FormAuto'));
const EditaCliente = React.lazy(() => import('./views/catalogos/clientes/EditaCliente'));
const FormClienteAuto = React.lazy(() => import('./views/catalogos/clientes/FormClienteAuto'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

const Servicio = React.lazy(() => import('./views/catalogos/servicios/Servicio'));
const FormConcepto = React.lazy(() => import('./views/catalogos/servicios/FormServicio'));
//------Fin Catálogos-----------

//--------Servicios-------------
const Mecanico = React.lazy(() => import('./views/servicios/mecanico/Mecanico'));
const FormServicio = React.lazy(() => import('./views/servicios/mecanico/FormServicio'));
//------Fin Servicios-----------

//----------Ya estaba---------------
const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
//----Fin de lo que ya estaba-------

//------------------------------------------------------------
//---------------------------- ROUTES-------------------------
//------------------------------------------------------------

const routes = [

  //----------Administracón--------------
  { path: '/admin/users/lista', name: 'Usuarios', component: Usuario },
  { path: '/admin/users/formUser', name: 'Modificación de Usuarios', component: FormUser },
  { path: '/admin/roles/lista', name: 'Roles', component: Rol },
  { path: '/admin/roles/formRol', name: 'Modificación de Perfiles', component: FormRol },

  { path: '/admin/sol/calculo', name: 'Ruta del Sol', component: SunCalc },
  //------Fin de Administración-----------

  //--------Catálogos-------------
  { path: '/catalogos/clientes/lista', name: 'Clientes', component: Cliente },
  { path: '/catalogos/clientes/formCliente', name: '_FormCliente', component: FormCliente },
  { path: '/catalogos/clientes/formAuto', name: 'Autos', component: FormAuto },
  { path: '/catalogos/clientes/editaCliente/:id', name: 'Edita Cliente', component: EditaCliente },
  //{ path: '/catalogos/clientes/formClienteAuto/:id', name: 'Autos del cliente', component: FormClienteAuto },
  { path: '/catalogos/clientes/formClienteAuto', name: 'Autos del cliente', component: FormClienteAuto },

  { path: '/catalogos/servicios/lista', name: 'Conceptos', component: Servicio },
  { path: '/catalogos/servicios/formConcepto', name: 'Modificación de Conceptos', component: FormConcepto },
  //------Fin Catálogos-----------

  //--------Servicios-------------
  { path: '/servicios/mecanico/lista', name: 'Servicio', component: Mecanico },
  { path: '/servicios/mecanico/formServicio', name: 'Servicios', component: FormServicio },

  //{ path: '/servicios/mecanico', name: '_FormCliente', component: FormCliente },
  //------Fin Servicios-----------

  //------Inicia lo que ya estaba--------
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
  //-----Fin de lo que ya estaaba--------
  
];

export default routes;
