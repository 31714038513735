import React, { useContext, useEffect } from 'react';
//import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../reusable/UserContext';
import { types } from '../../../reusable/types';
import taller  from '../../../assets/icons/taller.jpg';
import { useForm } from '../../../reusable/useForm';
import Swal from 'sweetalert2';
//import { PublicRoute } from '../../../routers/PublicRoute';
//import { authReducer } from '../../../reusable/authReducer'
//import { startLogin } from './auth'
import Cookies from 'universal-cookie';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

const cookies = new Cookies();
//const bcrypt = require('bcryptjs'); 
/* cookies.remove('850b78c59cc14a624092beb627a749e7', {path: "/login"});  //id_usuario
cookies.remove('21db768ea9cf0983953638fc381728bd', {path: "/login"}); //id_taller
cookies.remove('taller', {path: "/login"}); 
cookies.remove('nombrecompleto', {path: "/login"});
 */
export const Login = ({ history }) => {
  
  const { dispatch, setUserLogin, setUserData } = useContext( UserContext ); 
  const baseURL = process.env.REACT_APP_API_URL;

  const [ formValues, handleInputChange ] = useForm({
    user: '',
    pass: ''
  });

  const { user, pass } = formValues;

  useEffect(() => {
    
  }, []);
  
  function postData(){
    
    fetch(baseURL+'admin/users/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      user : user,
      pass :pass
    })
    })
    .then((response) => response.json())
    
    .then((responseJson) => {                    
      if (responseJson.respuesta === "esValido"){   
        var resp = responseJson.lista;
        //console.log('Mi Lista...'+ JSON.stringify(responseJson.lista));             
        localStorage.setItem( 'user.logged', JSON.stringify(true) );
        Swal.fire('Bienvenido',resp.nombrecompleto,'success');
        cookies.set('850b78c59cc14a624092beb627a749e7', resp.id_usuario, {path: "/"}); //id_usuario
        cookies.set('21db768ea9cf0983953638fc381728bd', resp.id_taller, {path: "/"}); //id_taller
        cookies.set('taller', resp.taller, {path: "/"}); 
        cookies.set('nombrecompleto', resp.nombrecompleto, {path: "/"});  
        /* <Redirect to="/dashborad" /> */
                 
        setUserData({
          idUser: resp.id_usuario,
          idRol: resp.id_rol,
          idTaller: resp.id_taller,
          name: resp.nombrecompleto, 
          userName: resp.usuario, 
          taller: resp.taller,
        });
        setUserLogin(true);  

      }else{
        console.log('Respuesta...'+responseJson.respuesta);
        localStorage.setItem( 'user.logged', JSON.stringify(false) );
        console.log('Error...'+localStorage.getItem('user.logged'));        
        Swal.fire('Usuario no válido','','error');
        /* <Redirect to="/login" /> */
        setUserLogin(false); 

        dispatch({
          type: types.logout
        });    
      } 
    })
    .catch((error) => {
      //Error
      alert(JSON.stringify(error));
      console.error(error);
    });
  }

  const handleLogin = (e) => {

    e.preventDefault();

    // const lastPath = localStorage.getItem('lastPath') || '/';
    const lastPath = '/';
       
    //Lanza la consulta
    postData();

    dispatch({
      type: types.login
    });
    history.replace( lastPath ); 

  }

  return (

    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
      <form onSubmit={ handleLogin }>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Introduce los datos de tu cuenta</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput 
                        type="text" 
                        placeholder="Usuario" 
                        autoComplete="off" 
                        name = "user"
                        value={ user }
                        onChange={ handleInputChange }
                    />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput 
                        type="password" 
                        placeholder="Password" 
                        name = "pass"
                        value={ pass }
                        onChange={ handleInputChange }
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" 
                        className="btn_login px-4"
                        onClick={ handleLogin }
                        >Login</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton className="px-0">Olvidaste tu password?</CButton>
                      </CCol>
                    </CRow>
                </CCardBody>
              </CCard>
              <CCard className="login_registrate py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Regístrate</h2>
                    <div>                    
                    <img src={taller} className="img-fluid" alt=""/>
                    </div>
                    <p></p>
                    <p>Si aún no cuentas con el sistema de TallerMec, puedes contactarnos por los siguientes medios</p>
                    <Link to="/register">
                      <CButton  className="btn_contacto mt-3" active tabIndex={-1}>Contáctanos!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        </form>
      </CContainer>
    </div>
    
  )
}

export default Login;
