import React, { useReducer, useEffect, useState } from 'react'
import { AppRouter } from './AppRouter'
import { UserContext } from './reusable/UserContext'
import { authReducer } from './reusable/authReducer'
import './scss/style.scss';

const init = () => {
    return JSON.parse(localStorage.getItem('user')) || { logged: false };
}

const usuarioIni={
    idUser: 0,
    idRol: 0,
    idTaller:-1,
    name:'Desconocido', 
    userName:null,
    taller: 'No asignado', 
};

export const App = () => {
        
    const [ user, dispatch ] = useReducer(authReducer, {}, init);
    const [userLogin, setUserLogin] = useState(false);
    const [userData, setUserData] = useState(usuarioIni);
    
    useEffect(() => {
        localStorage.setItem( 'user', JSON.stringify(user) );
    }, [user]);    

    return (
        /* <UserContext.Provider value={{ user, dispatch }}> */
        <UserContext.Provider value={{ userLogin, setUserLogin, userData, setUserData, user, dispatch }}>
            <AppRouter />
        </UserContext.Provider>
    )
}

export default App;